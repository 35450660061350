import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Solutions from 'components/layout/Sections/Solutions'
import Steps from 'components/layout/Sections/Steps'
import Products from 'components/layout/Sections/Products'
import VideoSection from 'components/layout/Sections/Video'

import Navigation from 'components/layout/Navigation/index'

import LazyLoad from 'react-lazyload'

const About = React.lazy(() => import('components/layout/Sections/About'))

const Activity = React.lazy(
  () => import('components/layout/Sections/SocialActivity')
)

const ContactForm = React.lazy(
  () => import('components/layout/Forms/ContactForm/ContactForm')
)

const aboutDescriptionTitle = 'Zawsze <br/> na froncie'

const activityHeading = 'Wiadomości'

const IndexPage: React.FC<PageProps<Queries.IndexPageQuery>> = ({ data }) => {
  const HOMEPAGE = data?.wpPage?.Homepage
  const PAGE_SEO = data?.wpPage?.seo

  const HERO_SLIDES =
    React.useMemo(
      () =>
        HOMEPAGE?.bannerHero?.map((item) => ({
          src: item?.bannerHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: item?.bannerHeroImg?.altText!,
          heading: item?.bannerHeroText!,
        })),
      []
    ) ?? []

  const SOLUTIONS_GALLERY = [
    {
      img: HOMEPAGE?.tilesFence?.tilesFenceImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: HOMEPAGE?.tilesFence?.tilesFenceImg?.altText!,
      link: '/ogrodzenia/',
      title: HOMEPAGE?.tilesFence?.tilesFenceText!,
    },
    {
      img: HOMEPAGE?.tilesGates?.tilesGatesImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: HOMEPAGE?.tilesGates?.tilesGatesImg?.altText!,
      link: '/bramy-wjazdowe/',
      title: HOMEPAGE?.tilesGates?.tilesGatesText!,
    },
    {
      img: HOMEPAGE?.tilesDoorsGates?.tilesDoorsGatesImg?.localFile
        ?.childImageSharp?.gatsbyImageData!,
      alt: HOMEPAGE?.tilesDoorsGates?.tilesDoorsGatesImg?.altText!,
      link: '/automatyka/',
      title: HOMEPAGE?.tilesDoorsGates?.tilesDoorsGatesText!,
    },
    {
      img: HOMEPAGE?.tilesArchitecture?.tilesArchitectureImg?.localFile
        ?.childImageSharp?.gatsbyImageData!,
      alt: HOMEPAGE?.tilesArchitecture?.tilesArchitectureImg?.altText!,
      link: '/mala-architektura/',
      title: HOMEPAGE?.tilesArchitecture?.tilesArchitectureText!,
    },
    {
      img: HOMEPAGE?.tilesTeam?.tilesTeamImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: HOMEPAGE?.tilesTeam?.tilesTeamImg?.altText!,
      link: '/gatigo-lab/',
      title: HOMEPAGE?.tilesTeam?.tilesTeamText!,
    },
  ]

  const STEPS =
    HOMEPAGE?.leadTimeAdvantages?.map((item) => ({
      leadTimeAdvantagesText: String(item?.leadTimeAdvantagesText),
    })) ?? []

  const PRODUCTS =
    React.useMemo(
      () =>
        HOMEPAGE?.productsCollection?.map((item) => ({
          img: item?.productsCollectionImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          label: item?.productsCollectionCollectionName!,
          link: item?.link!,
          alt: item?.productsCollectionImg?.altText!,
        })),
      []
    ) ?? []

  const POSTS_GALLERY =
    React.useMemo(() => {
      const newestNewsPost = {
        img:
          data?.newestNewsPost.nodes[0].artykul?.articleArticleImg?.localFile
            ?.childImageSharp?.gatsbyImageData! ||
          data?.newestNewsPost.nodes[0].artykul?.articleHeroImg?.localFile
            ?.childImageSharp?.gatsbyImageData!,
        alt: 'article',
        label: data?.newestNewsPost.nodes[0].title!,
        link: `/wiadomosci/aktualnosci/${data?.newestNewsPost.nodes[0].slug}/`,
      }

      const newestTrendsPosts = data?.newestTrendsPosts?.nodes.map((item) => ({
        img:
          item?.artykul?.articleArticleImg?.localFile?.childImageSharp
            ?.gatsbyImageData! ||
          item?.artykul?.articleHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
        alt: 'article',
        label: item?.title!,
        link: `/wiadomosci/porady-i-trendy/${item?.slug}/`,
      }))

      return [newestNewsPost, ...newestTrendsPosts]
    }, []) ?? []

  return (
    <Layout>
      <SEO
        title={PAGE_SEO?.title ?? ''}
        description={PAGE_SEO?.metaDesc ?? ''}
        ogTitle={PAGE_SEO?.opengraphTitle}
        ogDescription={PAGE_SEO?.opengraphDescription}
        ogImage={PAGE_SEO?.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO?.twitterTitle}
        twitterDescription={PAGE_SEO?.twitterDescription}
        twitterImage={PAGE_SEO?.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={data?.heroImgMobile?.childImageSharp?.gatsbyImageData!}
        slides={HERO_SLIDES}
        buttonLabel={HOMEPAGE?.bannerButton!}
      />
      <Solutions
        heading={HOMEPAGE?.tilesTitle!}
        solutions={SOLUTIONS_GALLERY}
        backgroundMobile="primary100"
        backgroundDesktop="gray"
      />
      <Steps heading={HOMEPAGE?.leadTimeTitle!} steps={STEPS} />
      <Products heading={HOMEPAGE?.productsTitle!} products={PRODUCTS} />
      <VideoSection
        heading={HOMEPAGE?.innovationTitle!}
        videoUrl={HOMEPAGE?.innovationFilm!}
      />
      <LazyLoad height="75vh" offset={600} once>
        <React.Suspense fallback={<></>}>
          <About
            heading={HOMEPAGE?.aboutCompanyTitle!}
            descriptionTitle={aboutDescriptionTitle}
            description={HOMEPAGE?.aboutCompanyText!}
          />
        </React.Suspense>
      </LazyLoad>
      <LazyLoad height="75vh" offset={600} once>
        <React.Suspense fallback={<></>}>
          <Activity heading={activityHeading} postsGallery={POSTS_GALLERY} />
        </React.Suspense>
      </LazyLoad>
      <LazyLoad height={500} offset={800} once>
        <React.Suspense fallback={<></>}>
          <ContactForm />
        </React.Suspense>
      </LazyLoad>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    heroImgMobile: file(name: { eq: "hero-img-mobile-1" }) {
      childImageSharp {
        gatsbyImageData(
          width: 992
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    wpPage(slug: { regex: "/strona-glowna/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
      Homepage {
        bannerHero {
          bannerHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          bannerHeroText
        }
        bannerButton
        tilesTitle
        tilesFence {
          tilesFenceText
          tilesFenceImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        tilesGates {
          tilesGatesText
          tilesGatesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
        tilesDoorsGates {
          tilesDoorsGatesText
          tilesDoorsGatesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
        tilesArchitecture {
          tilesArchitectureText
          tilesArchitectureImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
        tilesTeam {
          tilesTeamText
          tilesTeamImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
        leadTimeTitle
        leadTimeAdvantages {
          leadTimeAdvantagesText
        }
        productsTitle
        productsCollection {
          productsCollectionCollectionName
          link
          productsCollectionImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        innovationTitle
        innovationFilm
        aboutCompanyTitle
        aboutCompanyText
      }
    }
    newestNewsPost: allWpAcrtile(
      filter: { artykul: { articleArticleTyp: { eq: "news" } } }
      sort: { order: DESC, fields: date }
      limit: 1
    ) {
      nodes {
        artykul {
          articleArticleTyp
          articleArticleImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1450
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          articleHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1450
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        slug
        title
      }
    }
    newestTrendsPosts: allWpAcrtile(
      filter: { artykul: { articleArticleTyp: { eq: "trends" } } }
      sort: { order: DESC, fields: date }
      limit: 3
    ) {
      nodes {
        artykul {
          articleArticleTyp
          articleArticleImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          articleHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        slug
        title
      }
    }
  }
`
